<script setup lang="ts" generic="T extends SelectOptionKey">
  import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ListboxLabel,
  } from '@headlessui/vue'
  import { useVuelidate } from '@vuelidate/core'
  import { requiredIf } from '@vuelidate/validators'
  import { useElementSize } from '@vueuse/core'
  import { isEqual } from 'lodash'
  import { Instance as TippyInstance } from 'tippy.js'
  import { computed, ref, unref, watch } from 'vue'
  import IconArrowDown from '@/assets/icons/ArrowDown.svg'
  import LayoutScrollbarShade from '@/modules/base/layouts/LayoutScrollbarShade.vue'
  import { SelectOption, SelectOptionKey } from './baseSelect'
  import BaseTooltip from './BaseTooltip.vue'

  const props = withDefaults(
    defineProps<{
      modelValue: T
      options: Readonly<SelectOption<T>[]>
      topKeys?: T[]
      label?: string
      placeholder?: string
      disabled?: boolean
      required?: boolean
      validateOnCreated?: boolean
      errorAsTooltip?: boolean
      labelTooltip?: string
      classes?: {
        listContainer?: string
        listItem?: string
        toggle?: string
      }
      panelFitContent?: boolean
    }>(),
    {
      placeholder: undefined,
      topKeys: () => [],
      label: '',
      disabled: false,
      labelTooltip: undefined,
      errorAsTooltip: false,
      classes: () => ({}),
      panelFitContent: false,
    }
  )

  const $v = useVuelidate(
    {
      modelValue: {
        required: requiredIf(() => props.required),
      },
    },
    computed(() => ({
      modelValue: props.modelValue,
    }))
  )

  if (props.validateOnCreated) {
    $v.value.$touch()
  }

  const errorMessage = computed(() => {
    return $v.value.$error ? unref($v.value.$errors[0].$message) : undefined
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', key: T): void
  }>()

  const modelValueTitle = computed(() => {
    const option = props.options.find((option) =>
      isEqual(option.key, props.modelValue)
    )
    return option?.shortTitle ?? option?.title ?? ''
  })

  const toggleText = computed(() => {
    if (props.modelValue || props.modelValue === 0) {
      return modelValueTitle.value
    }
    return props.placeholder
  })
  const getListboxButtonClasses = (open: boolean) => [
    {
      'border-white-var': open,
      'border-red-600': !!errorMessage.value && !open,
      'border-gray-var-600': !errorMessage.value && !open,
    },
    props.classes.toggle,
  ]

  const model = computed({
    get: () => props.modelValue,
    set: (option) => {
      if (option || option === 0) {
        emit('update:modelValue', option)
      }
    },
  })

  const topKeyOptions = computed(() =>
    props.topKeys
      .map((key) => props.options.find((option) => option.key === key))
      .filter((option): option is SelectOption<T> => option !== undefined)
  )
  const middleOptions = computed(() =>
    props.options.filter((option) => !props.topKeys.includes(option.key))
  )
  const allOptionsTopAdjusted = computed(() => [
    ...topKeyOptions.value,
    ...middleOptions.value,
  ])

  // Adjust the width of the listbox options to match the width of the button
  const container = ref<HTMLButtonElement>()
  const panel = ref<HTMLButtonElement>()
  const tooltip = ref<{ tippy: TippyInstance }>()
  const { width: containerWidth } = useElementSize(container)

  watch(panel, (_panel) => {
    if (_panel) {
      tooltip.value?.tippy.show()
    } else {
      tooltip.value?.tippy.hide()
    }
  })
</script>

<template>
  <Listbox
    v-slot="{ open }"
    v-model="model"
    as="div"
    :by="isEqual"
    class="flex flex-col gap-1.5 text-base"
    :disabled
  >
    <ListboxLabel
      v-if="label"
      class="font-light"
      :class="errorMessage ? 'text-red-600' : 'text-white-var'"
    >
      <span
        v-tooltip="
          labelTooltip
            ? {
                content: labelTooltip,
                options: {
                  placement: 'top',
                },
              }
            : undefined
        "
      >
        <slot :label name="labelContent">{{ label }}</slot>
      </span>
    </ListboxLabel>
    <div ref="container">
      <BaseTooltip
        ref="tooltip"
        tabindex="-1"
        :tippy-options="{
          theme: 'unstyled',
          trigger: 'manual',
          placement: 'bottom-start',
          offset: [0, 2],
          arrow: false,
          popperOptions: {
            strategy: 'fixed',
          },
        }"
      >
        <template #content>
          <ListboxButton
            v-tooltip="
              errorMessage && errorAsTooltip ? errorMessage : undefined
            "
            class="base-control hover:opacity-control disabled:not-allowed bg-black-var flex w-full gap-1 pr-3 text-left"
            :class="getListboxButtonClasses(open)"
            data-testid="base-select-button"
            :disabled="disabled || options.length === 0"
            :title="toggleText"
          >
            <slot name="toggleContent" :text="toggleText">
              <div
                class="truncate"
                :class="{ 'text-gray-var-400': model === undefined }"
                data-testid="base-select-placeholder"
              >
                {{ toggleText }}
              </div>
            </slot>
            <IconArrowDown
              class="ml-auto h-6 w-6 shrink-0"
              :class="[
                open ? '-scale-y-100' : 'scale-y-100',
                {
                  invisible: disabled || options.length === 0,
                },
              ]"
            />
          </ListboxButton>
        </template>
        <template #panel>
          <div
            v-if="open"
            ref="panel"
            :style="{
              width: panelFitContent ? 'fit-content' : containerWidth + 'px',
            }"
          >
            <ListboxOptions
              class="border-gray-var-700 bg-black-var z-20 flex translate-y-1 flex-col overflow-hidden rounded-lg border py-2 pl-4 pr-2 shadow-lg focus:outline-none"
              static
            >
              <LayoutScrollbarShade class="text-black-var pr-1">
                <div
                  class="gc-global-scrollbar gc-global-scrollbar-thin gc-global-scrollbar-white-var-30 max-h-60 overflow-y-auto"
                  :class="classes.listContainer"
                  data-testid="base-select-list-container"
                >
                  <ListboxOption
                    v-for="(option, index) in allOptionsTopAdjusted"
                    :key="index"
                    v-slot="{ active, selected }"
                    class="border-gray-var-600 relative mr-3 cursor-pointer select-none py-3 first:pt-1"
                    :class="[
                      classes.listItem,
                      {
                        'border-b': index === topKeyOptions.length - 1,
                      },
                    ]"
                    data-testid="base-select-option"
                    :value="option.key"
                  >
                    <div
                      v-tooltip="
                        option.tooltip
                          ? {
                              content: option.tooltip,
                              options: { placement: 'right' },
                            }
                          : undefined
                      "
                      class="mt-1.5 overflow-hidden text-ellipsis whitespace-nowrap"
                      :class="[
                        active ? 'text-gray-var-400 ' : 'text-white-var',
                        selected ? 'font-bold' : '',
                      ]"
                      :title="option.title"
                    >
                      <slot name="optionContent" :option>
                        <div class="flex flex-row gap-2">
                          <component :is="option.icon" class="h-5.5 w-5.5" />
                          <div data-testid="base-select-option-title">
                            {{ option.title }}
                          </div>
                        </div>
                      </slot>
                    </div>
                  </ListboxOption>
                </div>
              </LayoutScrollbarShade>
            </ListboxOptions>
          </div>
        </template>
      </BaseTooltip>
    </div>
    <div
      v-if="errorMessage && !errorAsTooltip"
      aria-live="assertive"
      class="text-red-600"
    >
      {{ errorMessage }}
    </div>
  </Listbox>
</template>
